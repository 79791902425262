import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import DrawerSide from "../DrawerSide"
import SettingCard from "./SettingCard"
import peopleIcon from "../../../images/people_icon.svg"
import courtIcon from "../../../images/court_icon.svg"
import { Link } from "gatsby"
import Head from "../Head"

const useStyles = makeStyles(theme => ({
  typoTitle: {
    fontSize: "25px",
    fontFamily: "Plus Jakarta Sans",
    color: "#0A3133",
    marginBottom: "20px",
    fontWeight: 400,
  },
  subContent: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  contentRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100vh",
    background: "#F9FAFC",
    border: "1px solid rgb(128 128 128 / 15%)",
    borderRadius: "15px",
  },
}))

function Settings() {
  const classes = useStyles()

  return (
    <>
      <Head pageTitle="Settings" />
      <DrawerSide>
        <div
          style={{
            maxWidth: "1250px",
            width: "100%",
            padding: "60px",
          }}
        >
          <div className={classes.subContent}>
            <Typography className={classes.typoTitle}>Settings</Typography>
          </div>
          <div className={classes.contentRoot}>
            <Link style={{ width: "30%" }}>
              <SettingCard
                desc="Add or change information about your products so customers know what to expect when booking."
                title="Products"
                icon={courtIcon}
                to="/venue/court-settings"
                style={{ textDecoration: "none" }}
              />
            </Link>
            <Link style={{ width: "30%" }}>
              <SettingCard
                desc="Add or change administration of users for your facility,Aor your facility"
                title="Manage users"
                icon={peopleIcon}
                to="/venue/manage-user"
                style={{ textDecoration: "none" }}
              />
            </Link>
            <Link style={{ width: "30%" }}>
              <SettingCard
                desc="Change billing information to receive payment for bookings."
                title="Billing"
                icon={courtIcon}
                to="/venue/billing"
                style={{ textDecoration: "none" }}
              />
            </Link>
          </div>
        </div>
      </DrawerSide>
    </>
  )
}

export default Settings
